@charset "UTF-8";

@media screen and (min-width: 1200px) {
  .pc .content {
    display: flex;
    align-items: center;
    width: 1200px;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 900px;
  display: flex;
  justify-content: space-between;
  background-image: url('../img/background.png');
  background-size: cover;
  font-family: -apple-system, 'Microsoft Yahei', 'PingFang SC', sans-serif;
}

.m {
  display: none;

  .content {
    width: 100%;
    height: 100%;
    min-height: 1560px;
    background-image: url('../img/background.png');
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .logo-m {
      background-image: url('../img/logo_m.png');
      background-size: cover;
      width: 434px;
      height: 134px;
      margin-top: 70px;
    }

    .adv {
      background-image: url('../img/m-adv.png');
      background-size: cover;
      background-size: 100% 100%;
      width: 879px;
      height: 1445px;
      margin-top: 18px;
    }

    .action {
      margin-top: 25px;
      color: #fff;
      font-size: 32px;
      line-height: 96px;
      text-align: center;
      width: 791px;
      position: relative;
      height: 96px;

      &-is-code {
        display: none;
        justify-content: center;

        &-btn {
          width: 360px;
          position: relative;

          & .border {
            position: absolute;
            width: 12px;
            height: 72px;
            top: 12px;
          }

          & .left {
            left: -12px;
          }

          & .right {
            right: -12px;
          }
        }

        &-left {
          position: relative;

          &-main {
            background-color: #000;
          }

          &-border {
            background-color: #000;
          }

          &-icon {
            width: 96px;
            min-width: 48px;
            height: 34px;
            min-height: 17px;
            position: absolute;
            top: -14px;
            right: -24px;
            flex-shrink: 0;
            color: #000;

            &-main {
              width: 96px;
              min-width: 48px;
              height: 34px;
              line-height: 34px;
              background-color: #fff600;
              font-size: 20px;
            }

            &-border {
              background-color: #fff600;
              width: 6px;
              min-width: 3px;
              height: 22px;
              min-height: 11px;
              top: 6px;
              position: absolute;

              &.left {
                left: -6px;
              }

              &.right {
                right: -6px;
              }
            }
          }
        }
      }
    }

    .footer-m {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 70px;
      font-size: 22px;

      .policy {
        margin-bottom: 12px;

        a {
          margin-right: 32px;
          text-decoration: none;
          color: #000;
        }
      }

      .record {
        a {
          text-decoration: none;
          color: #52b367;
        }
      }
    }
  }
}

.pc {
  display: none;
  width: 100%;
  height: 100%;

  .content {
    margin: 0 auto;
    min-width: 880px;
    height: calc(100% - 290px);
    padding-top: 95px;
    position: relative;
    min-height: 651px;

    .logo {
      position: absolute;
      left: 30px;
      top: 34px;
      background-image: url('../img/logo.png');
      background-size: cover;
      width: 195px;
      height: 40px;
    }

    .frame-left {
      min-height: 556px;
      min-width: 524px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;

      &-inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        .slogan {
          background-image: url('../img/slogan.png');
          background-size: cover;
          width: 524px;
          height: 134px;
          margin-bottom: 17px;
        }

        .icon {
          background-image: url('../img/frame_right.png');
          background-size: cover;
          width: 2.604rem;
          height: 3.073rem;
          min-width: 398px;
          max-width: 500px;
          min-height: 469px;
          max-height: 590px;
        }

        .invitation {
          width: 100%;
          height: 50px;

          &-container {
            display: none;

            &-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 17px;

              &-top {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;

                &-btn {
                  position: relative;
                  margin-right: 10px;

                  &-text {
                    cursor: pointer;
                    width: 100%;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: #242526;
                    line-height: 27px;
                    font-family: 'HarmonyOS Sans SC', sans-serif;
                  }

                  &-underline {
                    background: #242526;
                    width: 100%;
                    height: 1px;
                  }

                  &-icon {
                    width: 58px;
                    height: 20px;
                    display: flex;
                    position: absolute;
                    right: -68px;
                    bottom: 3px;

                    &-main {
                      width: 58px;
                      height: 21px;
                      background-color: #fff600;
                      line-height: 18px;
                      flex-shrink: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }

                    &-main-text {
                      font-size: 12px;
                    }

                    &-other {
                      height: 12px;
                      width: 3px;
                      background-color: #fff600;
                      margin-top: 4px;
                      flex-shrink: 0;
                    }
                  }
                }
              }

              &-bottom {
                margin-top: 7px;
                font-size: 12px;
                color: #242526;
                opacity: .4;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    background-color: #303133;
    height: 121px;
    width: 100vw;
    position: fixed;
    left: 0;
    bottom: 0;
    color: #c5c6cc;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;

    .container {
      width: 1200px;
      display: flex;
      justify-content: center;
      margin: 0 30px;

      .user {
        height: 60px;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .policy {
          margin-bottom: 12px;

          &-a {
            margin-right: 32px;
            text-decoration: none;
            color: #c5c6cc;
          }
        }

        .record {
          text-align: center;

          &-a {
            text-decoration: none;
            color: #6e7175;
          }
        }
      }
    }
  }
}

.invitation-mask {
  display: none;
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0 0 0 / 40%);

  .invitation-mask-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 21;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-top {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px 8px 0 0;

      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        background-color: #333;
        position: relative;
        cursor: pointer;

        &-mask {
          position: absolute;
          z-index: 3;
          background-image: url('../img/invitation_bg.png');
          background-size: cover;
        }

        &-title {
          color: #fff;
          position: relative;
          z-index: 4;
        }

        &-is-code {
          font-weight: bolder;
          color: #3fff88;
          z-index: 2;
        }

        &-is-click_text {
          font-size: 14px;
          color: #8d98b2;
          z-index: 4;
        }

        &-is-body {
          position: absolute;
          bottom: 0;
          right: -6px;
          background-size: cover;
          background-image: url('../img/blue-body.png');
          z-index: 4;
        }

        &-no-body {
          display: none;
          background-image: url('../img/yellow-body.png');
          background-size: cover;
          background-repeat: no-repeat;
          // background-size: 167px 130px;
        }
      }

      &-info {
        color: #8d98b2;
        font-size: 14px;
        text-align: center;
      }
    }

    &-line {
      position: absolute;
      display: flex;
      overflow: hidden;

      &-main {
        background-color: #fff;
        background-image: url('../img/invitation_line.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      &-other {
        width: 0;
        height: 0;
      }

      &-left {
        border-left-color: rgba(0 0 0 / 0%);
        border-right-color: #fff;
        border-style: solid;
      }

      &-right {
        border-right-color: rgba(0 0 0 / 0%);
        border-left-color: #fff;
        border-style: solid;
      }
    }

    &-bottom {
      background-color: #fff;
      position: relative;
      border-radius: 0 0 8px 8px;

      &-btn {
        display: flex;
        cursor: pointer;

        &-main {
          font-size: 16px;
          text-align: center;
          background-color: #3fff88;
          flex: 1;
        }

        &-other {
          background-color: #3fff88;
          margin-top: 6px;
        }
      }
    }

    &-toast {
      display: none;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: -91px;
      transform: translateX(-50%);
      border-radius: 60px;

      &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: auto;

        &-icon {
          background-size: cover;
          margin-right: 6px;
        }

        &-icon-error {
          background-image: url('../img/toast-error.png');
        }

        &-icon-success {
          background-image: url('../img/toast-success.png');
        }
      }
    }

    &-transition {
      opacity: 1;
      transition: opacity .5s linear;
    }
  }
}

.web-invitation-mask {
  .invitation-mask-dialog {
    height: 524px;
    width: 432px;

    &-top {
      height: 361px;

      &-container {
        width: 397px;
        height: 241px;
        margin-top: 28px;

        &-mask {
          width: 397px;
          height: 241px;
        }

        &-title {
          font-size: 24px;
          margin-top: 24px;
          margin-bottom: 24px;
        }

        &-is-code {
          font-size: 70px;
        }

        &-is-click_text {
          margin-top: 50px;
          margin-bottom: 19px;
        }

        &-is-body {
          width: 97px;
          height: 99px;
        }

        &-no-body {
          width: 167px;
          height: 149px;
        }
      }

      &-info {
        margin: 29px 60px 21px;
      }
    }

    &-line {
      height: 23px;
      top: 359px;

      &-main {
        width: 392px;
      }

      &-other {
        border-top: 11px solid #fff;
        border-bottom: 11px solid #fff;
        border-left-width: 10px;
        border-right-width: 10px;
      }

      &-left {
        transform: translate(.8px, .5px) scaleX(1.2);
      }

      &-right {
        transform: translate(-.8px, .5px) scaleX(1.2);
      }
    }

    &-bottom {
      height: 144px;

      &-btn {
        width: 228px;
        height: 52px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &-main {
          height: 52px;
          line-height: 52px;
        }

        &-other {
          width: 6px;
          height: 40px;
        }
      }

      &-confirm {
        display: none;
      }
    }

    &-toast {
      width: auto;
      padding: 0 24px;

      &-content {
        height: 59px;

        &-icon {
          width: 16px;
          height: 16px;
        }

        &-text {
          font-size: 16px;
        }
      }
    }
  }
}

.m-invitation-mask {
  .invitation-mask-dialog {
    height: 420.5px;
    width: 315px;

    &-top {
      height: 279.5px;
      justify-content: space-between;

      &-container {
        width: 283px;
        height: 171.5px;
        margin-top: 36px;

        &-mask {
          width: 283px;
          height: 171.5px;
        }

        &-title {
          font-size: 16px;
          margin-top: 18px;
          margin-bottom: 16px;
        }

        &-is-code {
          font-size: 50px;
        }

        &-is-click_text {
          margin-top: 24px;
        }

        &-is-body {
          width: 69px;
          height: 70.5px;
        }

        &-no-body {
          width: 112.5px;
          height: 87.5px;
        }
      }

      &-info {
        margin: 0 16px 14px;
        font-size: 12px;
        line-height: 18px;
      }
    }

    &-line {
      height: 18px;
      top: 278.5px;

      &-main {
        width: 279px;
      }

      &-other {
        border-top: 8px solid #fff;
        border-bottom: 8px solid #fff;
        border-left-width: 9px;
        border-right-width: 9px;
      }

      &-left {
        transform: translate(-.1px, 1px) scale(1.1, 1.1);
      }

      &-right {
        transform: translate(-.1px, 1px) scale(1.1, 1.1);
      }
    }

    &-bottom {
      display: flex;
      height: 125px;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      &-btn {
        width: 283px;
        height: 46px;

        &-main {
          height: 46px;
          line-height: 46px;
        }

        &-other {
          width: 3.5px;
          height: 36px;
        }
      }

      &-confirm {
        color: #cad2e5;
        font-size: 12px;
      }
    }

    &-toast {
      width: 100px;
      padding: 0 16px;

      &-content {
        height: 44px;

        &-icon {
          width: 16px;
          height: 16px;
        }

        &-text {
          font-size: 13px;
        }
      }
    }
  }
}

.copy-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  opacity: 0;
  overflow: hidden;
  user-select: none;

  .copy-area {
    width: 1px;
    resize: none;
    border: 0 solid rgba(0 0 0 / 0%);
    outline: none;
    user-select: none;
    color: transparent;
    background: transparent;
  }
}
